import React from 'react';
import * as Styled from '../components/Contact/Contact.styled';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

export default function contact() {
  return (
    <Layout>
      <SEO title="Contact" />
      <Styled.Container>
        <h1>Contact me</h1>
        <Styled.Form
          action="https://formspree.io/obendevski@gmail.com"
          method="POST"
        >
          <Styled.Fieldset>
            <Styled.Input
              placeholder="Name"
              name="name"
              type="text"
              required={true}
            />
          </Styled.Fieldset>
          <Styled.Fieldset>
            <Styled.Input
              placeholder="Email"
              type="email"
              name="email"
              required={true}
            />
          </Styled.Fieldset>
          <Styled.Fieldset>
            <Styled.Input
              placeholder="Phone Number (optional)"
              type="tel"
              name="phone_number"
            />
          </Styled.Fieldset>
          <Styled.Fieldset>
            <Styled.TextArea
              placeholder="You were saying...."
              required={true}
              name="message"
            />
          </Styled.Fieldset>
          <Styled.Fieldset>
            <Styled.Button
              type="submit"
              id="contact-submit"
              data-submit="...Sending"
            >
              Contact me
            </Styled.Button>
          </Styled.Fieldset>
          <Styled.Honeypot type="text" name="_gotcha" />
        </Styled.Form>
      </Styled.Container>
    </Layout>
  );
}
