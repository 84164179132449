import styled from 'styled-components';

export const Container = styled('section')`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  h1 {
    color: white;
  }
`;

export const Form = styled('form')`
  background: #f9f9f9;
  padding: 25px;
  margin: 100px 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
`;

export const Fieldset = styled('fieldset')`
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
`;

export const Input = styled('input')`
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px 0;
  border-radius: 4px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #2e9cca;
    padding: 10px 20px;
  }
  :-ms-input-placeholder {
    color: #2e9cca;
  }
`;

export const TextArea = styled('textarea')`
  height: 100px;
  max-width: 100%;
  resize: none;
  width: 100%;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #2e9cca;
    padding: 10px 20px;
  }
  :-ms-input-placeholder {
    color: #2e9cca;
  }
`;

export const Button = styled('button')`
  cursor: pointer;
  width: 100%;
  border: none;
  background: #2e9cca;
  color: #fff;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
  border-radius: 4px;
`;

export const Honeypot = styled('input')`
  display: none;
`;
